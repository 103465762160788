
















































import { defineComponent } from "@vue/composition-api";
import { sendPasswordResetEmail } from "@/plugins/firebase";
import FormBase from "@/components/FormBase.vue";
import FormItem from "@/components/FormItem.vue";

type DataType = {
  email: string;
  error: string | null;
  successMessage: string | null;
  isLoading: boolean;
};

export default defineComponent({
  name: "ForgotPasswordPage",
  components: { FormBase, FormItem },
  data: (): DataType => ({
    email: "",
    error: null,
    successMessage: null,
    isLoading: false,
  }),
  methods: {
    async onSubmit() {
      this.error = null;
      this.isLoading = true;

      await sendPasswordResetEmail(this.email)
        .then(() => {
          this.successMessage = "A recovery e-mail has been sent. Please check your e-mail inbox.";
        })
        .catch(error => {
          if (error.code !== "auth/user-not-found") {
            this.error = error.message;
          } else {
            this.successMessage = "A recovery e-mail has been sent. Please check your e-mail inbox.";
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onAlertClose() {
      this.error = null;
    },
  },
});
